import React, { Component } from 'react';
import { bool, func, number, object, oneOfType, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '../../components';
import { Minus, Plus } from 'lucide-react';
import css from './FieldNumberInput.module.css';

const FieldNumberInputComponent = props => {
  const {
    rootClassName,
    className,
    inputRootClass,
    customErrorText,
    id,
    label,
    optionalLabel,
    input,
    meta,
    onUnmount,
    isUncontrolled,
    inputRef,
    hideErrorMessage,
    min,
    max,
    step,
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const value = input?.value || 0;

  const handleChange = e => {
    const val = e.target.value;
    if (/^\d*$/.test(val)) {
      const newValue = val === '' ? '' : parseInt(val, 10);
      input.onChange(newValue);
    }
  };

  const { valid, invalid, touched, error } = meta;
  const isTextarea = input.type === 'textarea';

  const errorText = customErrorText || error;
  const hasError = !!customErrorText || !!(touched && invalid && error);

  const fieldMeta = { touched: hasError, error: errorText };

  const { type, ...inputWithoutType } = input;
  const { value: defaultValue, ...inputWithoutValue } = input;
  const refMaybe = inputRef ? { ref: inputRef } : {};

  const inputClasses =
    inputRootClass ||
    classNames(css.input, {
      [css.inputSuccess]: valid,
      [css.inputError]: hasError,
      [css.textarea]: isTextarea,
    });

  const inputProps = {
    className: inputClasses,
    id,
    ...refMaybe,
    ...inputWithoutType,
    ...rest,
    value: value || '',
    onChange: handleChange,
  };

  const classes = classNames(rootClassName || css.root, className);

  const increment = () => {
    const newValue = Math.min(value + step, max);
    input.onChange(newValue);
  };

  const decrement = () => {
    const newValue = Math.max(value - step, min);
    input.onChange(newValue);
  };

  return (
    <div className={classes}>
      {label ? (
        <label htmlFor={id} className={optionalLabel && css.inlineLabel}>
          {label}
        </label>
      ) : null}
      {label && optionalLabel ? <span className={css.optional}>{optionalLabel}</span> : null}
      <div className={css.numberInput}>
        <button type="button" onClick={decrement} disabled={value <= min}>
          <span className={css.buttonIconWrapper}>
            <Minus className={css.buttonIcon} />
          </span>
        </button>
        <input type="text" inputMode="numeric" pattern="[0-9]*" {...inputProps} />
        <button type="button" onClick={increment} disabled={value >= max}>
          <span className={css.buttonIconWrapper}>
            <Plus className={css.buttonIcon} />
          </span>
        </button>
      </div>

      {hideErrorMessage ? null : <ValidationError fieldMeta={fieldMeta} />}
    </div>
  );
};

FieldNumberInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  optionalLabel: null,
  isUncontrolled: false,
  inputRef: null,
  min: 0,
  max: 100,
  step: 1,
  onChange: () => {},
};

FieldNumberInputComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,
  onUnmount: func,
  customErrorText: string,
  id: string,
  label: string,
  optionalLabel: string,
  isUncontrolled: bool,
  inputRef: object,
  input: shape({
    onChange: func.isRequired,
    value: oneOfType([string, number]),
  }).isRequired,
  meta: object.isRequired,
  onChange: func.isRequired,
  min: number,
  max: number,
  step: number,
};

class FieldNumberInput extends Component {
  componentWillUnmount() {
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <Field component={FieldNumberInputComponent} {...this.props} />;
  }
}

export default FieldNumberInput;
