import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconTwitterX.module.css';

const IconTwitterX = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 512 512">
      <path d="M281.7 234.3 382.2 120h-23.8l-87.3 99.2-69.7-99.2H121l105.4 150L121 389.9h23.8L237 285.1l73.6 104.8H391ZM153.4 137.5H190l168.4 235.6h-36.6Z" />
    </svg>
  );
};

const { string } = PropTypes;

IconTwitterX.defaultProps = {
  className: null,
  rootClassName: null,
};

IconTwitterX.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconTwitterX;
