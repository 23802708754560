import { pick } from 'lodash';
import { getPayments } from '../../util/api';

// ================ Action types ================ //
export const SET_INITIAL_VALUES = 'app/PaymentsPage/SET_INITIAL_VALUES';
export const FETCH_PAYMENTS_REQUEST = 'app/PaymentsPage/FETCH_PAYMENTS_REQUEST';
export const FETCH_PAYMENTS_SUCCESS = 'app/PaymentsPage/FETCH_PAYMENTS_SUCCESS';
export const FETCH_PAYMENTS_ERROR = 'app/PaymentsPage/FETCH_PAYMENTS_ERROR';
export const SET_ROWS_PER_PAGE = 'app/PaymentsPage/SET_ROWS_PER_PAGE';
export const SET_CURRENT_PAGE = 'app/PaymentsPage/SET_CURRENT_PAGE';
export const SET_SEARCH_QUERY = 'app/PaymentsPage/SET_SEARCH_QUERY';
export const SET_LAST_PAYMENT_ID = 'app/PaymentsPage/SET_LAST_PAYMENT_ID';

// ================ Initial State ================ //
const defaultApiRowsPerPage = 25;
const initialState = {
  payments: [],
  loading: false,
  error: null,
  uiRowsPerPage: 5,
  currentPage: 0,
  searchQuery: '',
  lastPaymentId: null,
  totalCount: 0,
};

// ================ Reducer ================ //
export default function stripePaymentsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_PAYMENTS_REQUEST:
      return { ...state, loading: true, error: null };

    case FETCH_PAYMENTS_SUCCESS:
      const existingPaymentIds = new Set(state.payments.map(payment => payment.id));
      const newPayments = payload?.payments?.filter(payment => !existingPaymentIds.has(payment.id));

      return {
        ...state,
        loading: false,
        totalCount: payload?.totalCount,
        payments: [...state.payments, ...newPayments],
        lastPaymentId:
          newPayments.length > 0 ? newPayments[newPayments.length - 1].id : state.lastPaymentId,
      };

    case FETCH_PAYMENTS_ERROR:
      return { ...state, loading: false, error: payload };

    case SET_ROWS_PER_PAGE:
      return {
        ...state,
        uiRowsPerPage: payload,
        currentPage: 0,
        // payments: [], // Clear payments to re-fetch
      };

    case SET_CURRENT_PAGE:
      return { ...state, currentPage: payload };

    case SET_SEARCH_QUERY:
      return { ...state, searchQuery: payload, currentPage: 0, payments: [], lastPaymentId: null };

    case SET_LAST_PAYMENT_ID:
      return { ...state, lastPaymentId: payload };

    default:
      return state;
  }
}

// ================ Thunks to fetch payments ================ //
export const fetchPayments = (rowsPerPage, lastId, searchQuery) => dispatch => {
  dispatch(fetchPaymentsRequest());

  const apiRowsPerPage = rowsPerPage > defaultApiRowsPerPage ? rowsPerPage : defaultApiRowsPerPage;

  return getPayments(apiRowsPerPage, lastId, searchQuery)
    .then(response => {
      dispatch(
        fetchPaymentsSuccess({ payments: response.payments, totalCount: response.totalCount })
      );
    })
    .catch(error => {
      dispatch(fetchPaymentsError(error.message));
    });
};

// ================ Load initial payments data ================ //
export const loadData = () => (dispatch, getState) => {
  dispatch(fetchPaymentsRequest());
  const { uiRowsPerPage, searchQuery, lastPaymentId } = getState().StripePaymentsPage;

  // Clear state so that previously loaded data is not visible
  dispatch(setInitialValues());

  return getPayments(defaultApiRowsPerPage, lastPaymentId, searchQuery)
    .then(response => {
      dispatch(
        fetchPaymentsSuccess({ payments: response.payments, totalCount: response.totalCount })
      );
    })
    .catch(error => {
      dispatch(fetchPaymentsError(error.message));
    });
};

// ================ Thunks for updating filters ================ //

export const setRowsPerPage = rowsPerPage => (dispatch, getState) => {
  const state = getState().StripePaymentsPage;
  const { payments } = state;

  // Set new rows per page and reset to the first page
  dispatch({ type: SET_ROWS_PER_PAGE, payload: rowsPerPage });
  dispatch({ type: SET_CURRENT_PAGE, payload: 0 });

  // Check if we have enough payments for the first page after changing rows per page
  const requiredPayments = rowsPerPage; // How many payments we need for the new page

  if (payments.length >= requiredPayments) {
    // If we already have enough data, no need to fetch
    return;
  }

  // If we don't have enough data, fetch more payments
  dispatch(fetchPayments(rowsPerPage, null, ''));
};

export const setCurrentPage = currentPage => (dispatch, getState) => {
  const state = getState().StripePaymentsPage;
  const { uiRowsPerPage, searchQuery, payments } = state;

  // Set the new current page
  dispatch({ type: SET_CURRENT_PAGE, payload: currentPage });

  // Calculate the starting index for the current page
  const startIndex = currentPage * uiRowsPerPage;
  const endIndex = startIndex + uiRowsPerPage;

  // Check if we have enough payments for the requested page
  if (payments.length >= endIndex) {
    // We already have enough payments for the requested page, no need to fetch
    return;
  }

  // If we don't have enough payments, fetch more payments
  const lastPaymentId = payments.length > 0 ? payments[payments.length - 1].id : null;
  dispatch(fetchPayments(uiRowsPerPage, lastPaymentId, searchQuery));
};

// ================ Set Search Query ================ //
export const setSearchQuery = searchQuery => dispatch => {
  dispatch({ type: SET_SEARCH_QUERY, payload: searchQuery });
  dispatch(fetchPayments(initialState.uiRowsPerPage, null, searchQuery)); // Reset to page 0 on search
};

// ================ Action creators ================ //
export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});
export const fetchPaymentsRequest = () => ({ type: FETCH_PAYMENTS_REQUEST });
export const fetchPaymentsSuccess = payload => ({
  type: FETCH_PAYMENTS_SUCCESS,
  payload: payload,
});
export const fetchPaymentsError = error => ({
  type: FETCH_PAYMENTS_ERROR,
  payload: error,
});

// ================ Method to get a payment by ID ================ //
export const getPaymentById = id => (dispatch, getState, sdk) => {
  return sdk
    .fetchPaymentById(id)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw new Error(error.message);
    });
};
