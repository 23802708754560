import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconEmail.module.css';

const IconEmail = props => {
  const { className, rootClassName, size } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="51"
      height="51"
      viewBox="0 0 51 51"
      xmlns="http://www.w3.org/2000/svg">
      <path d="m 33.697133,17.807145 h -16.3942 c -1.1998,0 -2.175766,0.97597 -2.175766,2.17576 v 11.03445 c 0,1.1995 0.975966,2.1755 2.175766,2.1755 h 16.3942 c 1.1998,0 2.1757,-0.976 2.1757,-2.1755 v -11.03445 c 0,-1.19979 -0.9759,-2.17576 -2.1757,-2.17576 z m 0,1.13986 c 0.0435,0 0.0853,0.0076 0.1274,0.01291 l -8.3245,7.63714 -8.32457,-7.63714 c 0.04218,-0.0053 0.0839,-0.01291 0.12747,-0.01291 z m 1.0359,12.07035 c 0,0.5709 -0.4647,1.0356 -1.0359,1.0356 h -16.3942 c -0.5712,0 -1.0359,-0.4647 -1.0359,-1.0356 v -11.03445 c 0,-0.09474 0.01682,-0.18487 0.0408,-0.272 l 6.31038,5.78925 -3.97626,3.6477 c -0.23189,0.2126 -0.24757,0.5733 -0.03481,0.8052 0.11249,0.1224 0.26601,0.1846 0.42022,0.1846 0.13761,0 0.27568,-0.0495 0.38517,-0.15 l 4.04864,-3.7142 1.65346,1.5168 c 0.109,0.1 0.2471,0.15 0.3852,0.15 0.138,0 0.2763,-0.05 0.3854,-0.15 l 1.6534,-1.5168 4.0486,3.7142 c 0.1095,0.1005 0.2476,0.15 0.3852,0.15 0.1542,0 0.308,-0.062 0.4202,-0.1846 0.2128,-0.2319 0.1973,-0.5926 -0.0345,-0.8052 l -3.9763,-3.6477 6.3104,-5.78902 c 0.0239,0.08713 0.0408,0.17726 0.0408,0.272 z" />
    </svg>
  );


};

const { string } = PropTypes;

IconEmail.defaultProps = {
  className: null,
  rootClassName: null,
};

IconEmail.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconEmail;
