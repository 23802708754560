// See: https://en.wikipedia.org/wiki/ISO_4217
// See: https://stripe.com/docs/currencies
export const subUnitDivisors = {
  AUD: 100,
  BGN: 100,
  CAD: 100,
  CHF: 100,
  CNY: 100,
  CZK: 100,
  DKK: 100,
  EUR: 100,
  GBP: 100,
  HKD: 100,
  INR: 100,
  JPY: 1,
  MXN: 100,
  NOK: 100,
  NZD: 100,
  PLN: 100,
  RON: 100,
  SEK: 100,
  SGD: 100,
  USD: 100,
};

/**
 * Currencies supported for setting a listing price.
 *
 * See: https://www.sharetribe.com/help/en/articles/8418388-countries-and-currencies-supported-by-stripe
 *
 */
export const supportedCurrencies = [
  { key: 'AUD', label: 'Australian Dollar (AUD)' },
  { key: 'BGN', label: 'Bulgarian Lev (BGN)' },
  { key: 'CAD', label: 'Canadian Dollar (CAD)' },
  { key: 'CHF', label: 'Swiss Franc (CHF)' },
  { key: 'CZK', label: 'Czech Koruna (CZK)' },
  { key: 'DKK', label: 'Danish Krone (DKK)' },
  { key: 'EUR', label: 'Euro (EUR)' },
  { key: 'GBP', label: 'British Pound Sterling (GBP)' },
  { key: 'HKD', label: 'Hong Kong Dollar (HKD)' },
  { key: 'JPY', label: 'Japanese Yen (JPY)' },
  { key: 'MXN', label: 'Mexican Peso (MXN)' },
  { key: 'NOK', label: 'Norwegian Krone (NOK)' },
  { key: 'NZD', label: 'New Zealand Dollar (NZD)' },
  { key: 'PLN', label: 'Polish Zloty (PLN)' },
  { key: 'RON', label: 'Romanian Leu (RON)' },
  { key: 'SEK', label: 'Swedish Krona (SEK)' },
  { key: 'SGD', label: 'Singapore Dollar (SGD)' },
  { key: 'USD', label: 'United States Dollar (USD)' },
];

/**
 * Currency formatting options.
 * See: https://github.com/yahoo/react-intl/wiki/API#formatnumber
 *
 * @param {string} currency
 */
export const currencyFormatting = (currency, options) => {
  const { enforceSupportedCurrencies = true } = options || {};
  if (enforceSupportedCurrencies && !subUnitDivisors[currency]) {
    const currencies = Object.keys(subUnitDivisors);
    throw new Error(
      `Configuration missing for currency: ${currency}. Supported currencies: ${currencies.join(
        ', '
      )}.`
    );
  }

  return subUnitDivisors[currency] === 1
    ? {
        style: 'currency',
        currency,
        currencyDisplay: 'code',
        useGrouping: true,
        // If the currency is not using subunits (like JPY), remove fractions.
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }
    : {
        style: 'currency',
        currency,
        currencyDisplay: 'code',
        useGrouping: true,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
};
