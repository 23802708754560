import { useEffect } from 'react';

function GSCObserver({ headerSelector, onWidgetAdded }) {
  useEffect(() => {
    // Function to observe changes in the GetSiteControl widget
    // The bar type widgets overlay the topbar and menu.
    // This observer pushes them above the main page.
    const observeGSCChange = () => {
      // Select all GSC widgets
      const gscWidgets = Array.from(document.querySelectorAll('getsitecontrol-widget'));

      gscWidgets.forEach(widget => {
        // Access the shadow root
        const shadowRoot = widget.shadowRoot;

        if (shadowRoot) {
          // Select child divs within the shadow root
          const childDivs = shadowRoot.querySelectorAll('div');

          childDivs.forEach(div => {
            // Check for the custom CSS variable
            const widgetStyle = getComputedStyle(div);
            const customKey = widgetStyle.getPropertyValue('--gsc-widget-key')?.trim();

            if (customKey === '"my-custom-widget"') {
              // Perform the action, e.g., position it at the top of the header
              const headerElement = document.querySelector(headerSelector);

              if (headerElement) {
                headerElement.insertAdjacentElement('beforebegin', widget);
                if (onWidgetAdded) onWidgetAdded(widget);
              }
            }
          });
        }
      });
    };

    // Poll for the element until it exists
    const checkForElement = setInterval(() => {
      if (document.querySelector('getsitecontrol-widget')) {
        clearInterval(checkForElement); // Stop the interval once the element is found
        observeGSCChange(); // Start the observer
      }
    }, 100);
    // Cleanup: Clear the interval when the component unmounts
    return () => clearInterval(checkForElement);
  }, [headerSelector, onWidgetAdded]); // Re-run the effect if the dependencies change

  return null; // This component doesn't render anything
}

export default GSCObserver;
