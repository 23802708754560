import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconWhatsApp.module.css';

const IconWhatsApp = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 512 512">
      <path d="m79 434 25.7-93.9a181.1 181.2 0 1170.3 68.7m-52.5-17.8 57-15a150.6 150.6 0 10-41.8-40.6m93-127c2 5 0 10-11 22.2-6 6-4 8 6.6 23s28 29 44 36.5 15 7 21.7-1c15-17 11-21 26-14.2l27 13c8 4 8.4 4 8.5 9s-1.7 18-7 23.6-25 24.8-60 12-59-23-99-77-1.6-86 3.6-88 7-1.5 17-1.3q4 0 7 5" />
    </svg>
  );
};

const { string } = PropTypes;

IconWhatsApp.defaultProps = {
  className: null,
  rootClassName: null,
};

IconWhatsApp.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconWhatsApp;
