import React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { arrayOf, shape, string } from 'prop-types';
import classNames from 'classnames';
import { ChevronDown } from 'lucide-react';

import css from './Accordion.module.css';

const Accordion = props => {
  const { items } = props;

  if (items.length === 0) {
    return null;
  }

  return (
    <AccordionPrimitive.Root className={css.AccordionRoot} type="multiple" collapsible="true">
      {items.map((item, index) => (
        <AccordionPrimitive.Item key={index} className={css.AccordionItem} value={'item-' + index}>
          <AccordionTrigger>{item.header}</AccordionTrigger>
          <AccordionContent>{item.content}</AccordionContent>
        </AccordionPrimitive.Item>
      ))}
    </AccordionPrimitive.Root>
  );
};

const AccordionTrigger = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
  <AccordionPrimitive.Header className={css.AccordionHeader}>
    <AccordionPrimitive.Trigger
      className={classNames(css.AccordionTrigger, className)}
      {...props}
      ref={forwardedRef}
    >
      {children}
      <ChevronDown className={css.AccordionChevron} aria-hidden />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));

const AccordionContent = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
  <AccordionPrimitive.Content
    className={classNames('AccordionContent', className)}
    {...props}
    ref={forwardedRef}
  >
    <div className={css.AccordionContentText}>{children}</div>
  </AccordionPrimitive.Content>
));

Accordion.defaultProps = {
  items: [],
};

Accordion.propTypes = {
  items: arrayOf(shape({ header: string, content: string })),
};

export default Accordion;
