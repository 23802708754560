/**
 * Error logging
 *
 * Can be used to log errors to console or and eternal
 * error logging system, like Sentry for example.
 *
 */

import * as Sentry from '@sentry/react';
import appSettings from '../config/settings';
import CustomHistory from './history';

const rootUrl = process.env.REACT_APP_MARKETPLACE_ROOT_URL;

/**
 * Set up error handling. If a Sentry DSN is
 * provided a Sentry client will be installed.
 */
export const setup = () => {
  const history = CustomHistory();
  if (appSettings.sentryDsn) {
    // Configures the Sentry client. Adds a handler for
    // any uncaught exception.
    Sentry.init({
      dsn: appSettings.sentryDsn,
      environment: appSettings.env,
      release: process.env?.RENDER_GIT_COMMIT,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,

      // To see replays for backend errors
      tracePropagationTargets: [
        /^https:\/\/bookyrslf\.com\/api/,
        /^https:\/\/test\.bookyrslf\.com\/api/,
      ],

      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: process.env.REACT_APP_SENTRY_REPLAY_SAMPLE_RATE,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: process.env.REACT_APP_SENTRY_REPLAY_ERROR_SAMPLE_RATE,

      integrations: [
        Sentry.reactRouterV5BrowserTracingIntegration({ history }),
        Sentry.replayIntegration({
          // TODO: Generating an error - maybe version related
          // Additional SDK configuration goes in here, for example:
          maskAllText: false,
          blockAllMedia: false,
          maskAllInputs: true,
          networkDetailAllowUrls: [
            'https://upload.imagekit.io/api/v2/files/upload',
            `${rootUrl}/api`,
            'https://flex-api.sharetribe.com/v1/api',
          ],
          networkRequestHeaders: ['Authorization'],
          networkResponseHeaders: ['Location', 'x-ik-requestId'],
        }),
      ],
    });
  }
};

/**
 * Set user ID for the logger so that it
 * can be attached to Sentry issues.
 *
 * @param {String} userId ID of current user
 */
export const setUserId = userId => {
  Sentry.getCurrentScope().setTag('id', userId);
  // Sentry.configureScope(scope => {
  //   scope.setUser({ id: userId });
  // });
};

/**
 * Clears the user ID.
 */

export const clearUserId = () => {
  Sentry.getCurrentScope().setTag('id', '');
  // Sentry.configureScope(scope => {
  //   scope.setUser(null);
  // });
};

const printAPIErrorsAsConsoleTable = apiErrors => {
  if (apiErrors != null && apiErrors.length > 0 && typeof console.table === 'function') {
    console.log('Errors returned by Marketplace API call:');
    console.table(apiErrors.map(err => ({ status: err.status, code: err.code, ...err.meta })));
  }
};

const responseAPIErrors = error => {
  return error && error.data && error.data.errors ? error.data.errors : [];
};

const responseApiErrorInfo = err =>
  responseAPIErrors(err).map(e => ({
    status: e.status,
    code: e.code,
    meta: e.meta,
  }));

/**
 * Logs an exception. If Sentry is configured
 * sends the error information there. Otherwise
 * prints the error to the console.
 *
 * @param {Error} e Error that occurred
 * @param {String} code Error code
 * @param {Object} data Additional data to be sent to Sentry
 */
export const error = (e, code, data) => {
  const apiErrors = responseApiErrorInfo(e);
  if (appSettings.sentryDsn) {
    const extra = { ...data, apiErrorData: apiErrors };

    Sentry.withScope(scope => {
      scope.setTag('code', code);
      Object.keys(extra).forEach(key => {
        scope.setExtra(key, extra[key]);
      });
      Sentry.captureException(e);
    });

    printAPIErrorsAsConsoleTable(apiErrors);
  } else {
    console.error(e);
    console.error('Error code:', code, 'data:', data);
    printAPIErrorsAsConsoleTable(apiErrors);
  }
};

export const warn = (...args) => {};
export const debug = (...args) => {};
