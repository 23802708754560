import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconBookyrslfArrow.module.css';

const IconBookyrslfArrow = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="65" height="69" viewBox="0 0 65 69" xmlns="http://www.w3.org/2000/svg">
      <path d="M63.9285 32.4205L60.5544 29.1242C59.4638 28.0509 57.3081 27.1736 55.7745 27.1736H42.7723C40.1566 26.1771 41.9459 23.9455 41.9459 23.9455L47.0751 18.7412C48.1572 17.6424 48.1146 15.9048 46.9984 14.8572L32.0281 0.769031C30.912 -0.278635 29.1142 -0.253076 28.0321 0.82866L22.2893 6.5525C21.2072 7.62571 21.1987 9.4059 22.2723 10.4962L35.1892 23.5451C35.2914 23.6814 35.4192 23.8177 35.5897 23.954C35.5982 23.954 35.6067 23.971 35.6152 23.971L36.6377 25.0102C37.8391 26.6967 35.5897 27.1821 35.5897 27.1821H2.78616C1.25249 27.1821 0 28.4342 0 29.9674H0.00850271V38.7746C0.00850271 40.3078 1.26101 41.5599 2.78616 41.5599H35.4874C37.0211 41.5599 37.3875 42.4457 36.3054 43.5274L22.2893 57.5304C21.2072 58.6121 21.2243 60.3668 22.3234 61.44L28.7308 67.6152C29.8384 68.6714 31.6191 68.6629 32.7012 67.5812L63.9455 36.3471C65.0276 35.2654 65.0106 33.5022 63.9199 32.429L63.9285 32.4205Z" />
    </svg>
  );
};

const { string } = PropTypes;

IconBookyrslfArrow.defaultProps = {
  className: null,
  rootClassName: null,
};

IconBookyrslfArrow.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconBookyrslfArrow;
