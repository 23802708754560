import { sendMetaConversion } from './api';
import { createSlug } from './urlHelpers';
import * as log from './log';

const rootUrl = process.env.REACT_APP_MARKETPLACE_ROOT_URL;
const conversionLabelSignup = process.env.REACT_APP_GOOGLE_CONVERSION_LABEL_SIGNUP;
const conversionLabelPurchase = process.env.REACT_APP_GOOGLE_CONVERSION_LABEL_PURCHASE;
const googleTagId = process.env.REACT_APP_GTAG_ID;

/**
 * Sends a purchase conversion event to both Meta and Google Ads.
 *
 * @param {Object} transaction - The transaction data used for conversion tracking
 */
export const sendPurchaseConversion = transaction => (dispatch, getState, sdk) => {
  sendMetaConversionEvent(transaction, 'Purchase');
  sendGooglePurchaseConversionEvent(transaction);
};

/**
 * Sends a signup conversion event to Google Ads.
 *
 * @param {string} userType - The type of user signing up
 * @param {string} email - The email of the user signing up (optional)
 */
export const sendGoogleSignupConversionEvent = (userType, email) => (dispatch, getState, sdk) => {
  const sendEvent = () => {
    window?.gtag('event', 'conversion', {
      send_to: `${googleTagId}/${conversionLabelSignup}`,
      value: 0, // For signups, the value is typically 0
      currency: 'CAD',
      user_type: userType,
    });
  };

  try {
    // Send the event immediately if gtag is available, otherwise delay it by 10 seconds
    if (typeof window !== 'undefined' && window?.gtag) {
      sendEvent();
    } else {
      setTimeout(sendEvent, 10000); // Retry after 10 seconds if gtag isn't available immediately
    }
  } catch (e) {
    log.error(e); // Log any errors
  }
};

/**
 * Sends a Google Ads conversion event for a purchase.
 *
 * @param {Object} transaction - The transaction object with purchase details
 */
const sendGooglePurchaseConversionEvent = transaction => {
  const transactionId = transaction?.id?.uuid; // Extract the transaction ID
  const price = transaction?.attributes?.payinTotal; // Extract the total price
  const amount = (price?.amount ?? 0) / 100; // Convert cents to dollars if available, else default to 0
  const listingType = transaction?.listing?.attributes?.publicData?.listingType; // Extract listing type

  const sendEvent = () => {
    window?.gtag('event', 'conversion', {
      send_to: `${googleTagId}/${conversionLabelPurchase}`,
      value: amount, // Set the transaction value
      currency: price?.currency ?? 'CAD', // Default currency is CAD if not provided
      transaction_id: transactionId, // Include transaction ID for tracking
      listing_type: listingType, // Include listing type as a custom parameter
    });
  };

  if (transactionId) {
    try {
      // Send the event immediately if gtag is available, otherwise delay it by 10 seconds
      if (typeof window !== 'undefined' && window?.gtag) {
        sendEvent();
      } else {
        setTimeout(sendEvent, 10000); // Retry after 10 seconds if gtag isn't available
      }
    } catch (e) {
      console.log(e); // Log any errors
    }
  }
};

/**
 * Sends a signup conversion event to Meta.
 *
 * @param {Object} currentUser - The current user object with signup details
 */
export const sendMetaSignupConversion = currentUser => (dispatch, getState, sdk) => {
  sendMetaConversionEvent(null, 'CompleteRegistration', currentUser); // Sends the registration event to Meta
};

/**
 * Sends a conversion event to Meta by forwarding it to the backend.
 *
 * @param {Object} transaction - The transaction data (optional)
 * @param {string} conversionType - The type of conversion (e.g., 'Purchase', 'CompleteRegistration')
 * @param {Object} currentUser - The current user object (optional)
 */
const sendMetaConversionEvent = (transaction, conversionType, currentUser) => {
  const transactionId = transaction?.id?.uuid;
  const listing = transaction?.listing;
  const listingId = listing?.id?.uuid;
  const listingType = listing?.attributes?.publicData?.listingType;
  let slug = '';

  // Generate a slug based on listing type or user data
  if (listingType === 'hidden_provider_inquiry') {
    slug = createSlug(transaction?.provider?.attributes?.profile?.displayName); // Slug for provider inquiry
  } else if (listing) {
    slug = createSlug(listing?.attributes?.title); // Slug for listing title
  } else if (currentUser) {
    slug = ''; // No slug for current user
  }

  // Create the event source URL based on the listing or user's signup source
  const eventSourceUrl = `${rootUrl}/${
    listing
      ? `l/${slug}/${listingId}`
      : `${currentUser?.attributes?.profile?.privateData?.signupFromUrl}`
  }`;

  // If a transaction exists, send the conversion event to Meta
  if (transactionId) {
    try {
      sendMetaConversion({
        transactionId: transactionId,
        eventName: conversionType,
        eventSourceUrl: eventSourceUrl,
      }).then(response => {
        // console.log(response); // Log the Meta API response
      });
    } catch (e) {
      console.log(e); // Log any errors
    }
  }

  // If there's a pending Meta signup conversion, send that event too
  if (currentUser && currentUser?.attributes?.profile?.privateData?.isMetaSignupConversionPending) {
    try {
      sendMetaConversion({
        eventName: conversionType,
        eventSourceUrl: eventSourceUrl,
      }).then(response => {
        // console.log(response); // Log the Meta API response
      });
    } catch (e) {
      console.log(e); // Log any errors
    }
  }
};
