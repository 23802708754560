import { useEffect } from 'react';

function IntercomObserver({ onMessengerOpen, onMessengerClosed }) {
  useEffect(() => {
    // Function to observe changes in the Intercom Messenger iframe's class and style
    const observeIntercomChange = () => {
      const intercomMessengerFrame = document.querySelector('.intercom-messenger-frame');

      if (!intercomMessengerFrame) return; // Exit if the element is not found

      // Create a new MutationObserver instance
      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
            // Class attribute has changed, now check other styles
            const computedStyle = window.getComputedStyle(intercomMessengerFrame);

            const pointerEvents = computedStyle.pointerEvents;

            // Validate the styles based on the conditions
            const isIframeOpen = pointerEvents === 'all';
            const isIframeClosed = pointerEvents === 'none';

            if (isIframeOpen && onMessengerOpen) {
              onMessengerOpen(); // Call the callback when the messenger opens
            }

            if (isIframeClosed && onMessengerClosed) {
              onMessengerClosed(); // Call the callback when the messenger closes
            }
          }
        });
      });

      // Configuration of the observer
      const config = {
        attributes: true, // Observe attribute changes
        attributeFilter: ['class'], // Only observe the 'class' attribute
      };

      // Start observing the target node
      observer.observe(intercomMessengerFrame, config);

      // Cleanup: Disconnect the observer when the component unmounts or when the element is no longer needed
      return () => observer.disconnect();
    };

    // Poll for the element until it exists
    const checkForElement = setInterval(() => {
      if (document.querySelector('.intercom-messenger-frame')) {
        clearInterval(checkForElement); // Stop the interval once the element is found
        observeIntercomChange(); // Start the observer
      }
    }, 100); // Adjust the interval time as needed

    // Cleanup: Clear the interval when the component unmounts
    return () => clearInterval(checkForElement);
  }, [onMessengerOpen, onMessengerClosed]); // Re-run the effect if the callbacks change

  return null; // This component doesn't render anything
}

export default IntercomObserver;
