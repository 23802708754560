import React from 'react';
import PropTypes, { number } from 'prop-types';
import classNames from 'classnames';
import { IconReviewCircle } from '../../components';
import { REVIEW_RATINGS } from '../../util/types';

const ReviewRating = props => {
  const { className, rootClassName, reviewRatingClassName, reviewCirclesClassName, reviewCircleClassName, rating } = props;
  const classes = classNames(rootClassName, className);

  const circles = REVIEW_RATINGS;
  return (
    <span className={classes} title={`${rating}/5`}>
      <span className={reviewRatingClassName}>{rating ? rating : null}</span>
      <span className={reviewCirclesClassName}>
        {circles.map(circle => (
          <IconReviewCircle
            key={`circle-${circle}`}
            className={reviewCircleClassName}
            isFilled={circle <= rating}
          />
        ))}
      </span>
    </span>
  );
};

ReviewRating.defaultProps = {
  rootClassName: null,
  className: null,
  reviewRatingClassName: null,
  reviewCircleClassName: null,
};

const { string, oneOf } = PropTypes;

ReviewRating.propTypes = {
  rating: number.isRequired,
  reviewRatingClassName: string,
  reviewCircleClassName: string,
  reviewCirclesClassName: string,
  rootClassName: string,
  className: string,
};

export default ReviewRating;
