/**
 * Transaction process graph for bookings:
 *   - default-booking
 */

/**
 * Transitions
 *
 * These strings must sync with values defined in Marketplace API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const transitions = {
  // When a customer makes a booking to a listing, a transaction is
  // created with the initial request-payment transition.
  // At this transition a PaymentIntent is created by Marketplace API.
  // The customer acceptance of the liability waiver is stored to protected data.
  // After this transition, the actual payment must be made on client-side directly to Stripe.
  REQUEST_PAYMENT: 'transition/request-payment',

  // A customer can also initiate a transaction with an inquiry, and
  // then transition that with a request.
  INQUIRE: 'transition/inquire',
  REQUEST_PAYMENT_AFTER_INQUIRY: 'transition/request-payment-after-inquiry',

  // Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
  // Therefore we need to make another transition to Marketplace API,
  // to tell that the payment is confirmed.
  CONFIRM_PAYMENT: 'transition/confirm-payment',

  // Provider or operator updates the booking after payment, start time or end time or metadata
  PROVIDER_UPDATE_BOOKING: 'transition/provider-update-booking',
  OPERATOR_UPDATE_BOOKING: 'transition/operator-update-booking',
  PROVIDER_UPDATE_BOOKING_LATE: 'transition/provider-update-booking-late',
  OPERATOR_UPDATE_BOOKING_LATE: 'transition/operator-update-booking-late',

  // If the payment is not confirmed in the time limit set in transaction process (by default 15min)
  // the transaction will expire automatically.
  EXPIRE_PAYMENT: 'transition/expire-payment',

  PROVIDER_UPDATE_REQUEST: 'transition/provider-update-request',

  // When the provider accepts or declines a transaction from the
  // SalePage, it is transitioned with the accept or decline transition.
  ACCEPT: 'transition/accept',
  DECLINE: 'transition/decline',

  // At 48 hours before start time automatic transition
  PASSED_POINT_OF_CUSTOMER_CANCEL_WITH_REFUND:
    'transition/passed-point-of-customer-cancel-with-refund',

  // When zoom call is created and provider starts call
  ZOOM_MEETING_CREATE_LATE: 'transition/zoom-meeting-create-late',
  ZOOM_MEETING_CREATE: 'transition/zoom-meeting-create',
  ZOOM_MEETING_PROVIDER_START_EARLY: 'transition/zoom-meeting-provider-start-early',
  ZOOM_MEETING_PROVIDER_START: 'transition/zoom-meeting-provider-start',

  // The operator can accept or decline the offer on behalf of the provider
  OPERATOR_ACCEPT: 'transition/operator-accept',
  OPERATOR_DECLINE: 'transition/operator-decline',

  // The backend automatically expire the transaction.
  EXPIRE: 'transition/expire',

  // Admin can also cancel the transition.
  CANCEL: 'transition/cancel',

  // Customer can cancel before provider accept or decline
  EARLY_CANCEL_CUSTOMER: 'transition/early-cancel-by-customer',

  // Customer can cancel after provider accept
  CANCEL_CUSTOMER: 'transition/cancel-by-customer',

  // Provider can cancel after provider accept
  CANCEL_PROVIDER: 'transition/cancel-by-provider',

  // Cancel within 48 hours of start time - no refund if customer cancel
  LATE_CANCEL_OPERATOR: 'transition/late-cancel-by-operator',
  LATE_CANCEL_CUSTOMER: 'transition/late-cancel-by-customer',
  LATE_CANCEL_PROVIDER: 'transition/late-cancel-by-provider',

  // Automatic transition at booking start time
  BOOKING_START: 'transition/booking-start',

  // Automatic transition at booking start time
  BOOKING_END: 'transition/booking-end',

  // The backend will mark the transaction completed.
  COMPLETE: 'transition/complete',
  OPERATOR_COMPLETE: 'transition/operator-complete',

  // Reviews are given through transaction transitions. Review 1 can be
  // by provider or customer, and review 2 will be the other party of
  // the transaction.
  REVIEW_1_BY_PROVIDER: 'transition/review-1-by-provider',
  REVIEW_2_BY_PROVIDER: 'transition/review-2-by-provider',
  REVIEW_1_BY_CUSTOMER: 'transition/review-1-by-customer',
  REVIEW_2_BY_CUSTOMER: 'transition/review-2-by-customer',
  EXPIRE_CUSTOMER_REVIEW_PERIOD: 'transition/expire-customer-review-period',
  EXPIRE_PROVIDER_REVIEW_PERIOD: 'transition/expire-provider-review-period',
  EXPIRE_REVIEW_PERIOD: 'transition/expire-review-period',
};

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
export const states = {
  INITIAL: 'initial',
  INQUIRY: 'inquiry',
  PENDING_PAYMENT: 'pending-payment',
  PAYMENT_EXPIRED: 'payment-expired',
  PREAUTHORIZED: 'preauthorized',
  DECLINED: 'declined',
  ACCEPTED: 'accepted',
  // Automatic transition from accepted to customer-requested-refund-no-longer-possible at 48 before start time
  CUSTOMER_REQUESTED_REFUND_NO_LONGER_POSSIBLE: 'customer-requested-refund-no-longer-possible',
  EXPIRED: 'expired',
  BOOKING_STARTED: 'booking-started',
  BOOKING_ENDED: 'booking-ended',
  CANCELED: 'canceled',
  DELIVERED: 'delivered',
  REVIEWED: 'reviewed',
  REVIEWED_BY_CUSTOMER: 'reviewed-by-customer',
  REVIEWED_BY_PROVIDER: 'reviewed-by-provider',
};

/**
 * Description of transaction process graph
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
export const graph = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'default-booking/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: states.INITIAL,

  // States
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.INQUIRE]: states.INQUIRY,
        [transitions.REQUEST_PAYMENT]: states.PENDING_PAYMENT,
      },
    },
    [states.INQUIRY]: {
      on: {
        [transitions.REQUEST_PAYMENT_AFTER_INQUIRY]: states.PENDING_PAYMENT,
      },
    },

    [states.PENDING_PAYMENT]: {
      on: {
        [transitions.EXPIRE_PAYMENT]: states.PAYMENT_EXPIRED,
        [transitions.CONFIRM_PAYMENT]: states.PREAUTHORIZED,
      },
    },

    [states.PAYMENT_EXPIRED]: {},
    [states.PREAUTHORIZED]: {
      on: {
        [transitions.DECLINE]: states.DECLINED,
        [transitions.OPERATOR_DECLINE]: states.DECLINED,
        [transitions.EXPIRE]: states.EXPIRED,
        [transitions.ACCEPT]: states.ACCEPTED,
        [transitions.OPERATOR_ACCEPT]: states.ACCEPTED,
        [transitions.EARLY_CANCEL_CUSTOMER]: states.CANCELED,
        [transitions.PROVIDER_UPDATE_REQUEST]: states.PREAUTHORIZED, // doesn't allow price update
      },
    },

    [states.DECLINED]: {},
    [states.EXPIRED]: {},
    [states.ACCEPTED]: {
      on: {
        [transitions.PASSED_POINT_OF_CUSTOMER_CANCEL_WITH_REFUND]:
          states.CUSTOMER_REQUESTED_REFUND_NO_LONGER_POSSIBLE,
        [transitions.CANCEL]: states.CANCELED,
        [transitions.CANCEL_CUSTOMER]: states.CANCELED,
        [transitions.CANCEL_PROVIDER]: states.CANCELED,
        [transitions.ZOOM_MEETING_CREATE]: states.ACCEPTED,
        [transitions.OPERATOR_UPDATE_BOOKING]: states.ACCEPTED,
        [transitions.PROVIDER_UPDATE_BOOKING]: states.ACCEPTED,
      },
    },

    [states.CUSTOMER_REQUESTED_REFUND_NO_LONGER_POSSIBLE]: {
      on: {
        [transitions.LATE_CANCEL_CUSTOMER]: states.CANCELED,
        [transitions.LATE_CANCEL_OPERATOR]: states.CANCELED,
        [transitions.LATE_CANCEL_PROVIDER]: states.CANCELED,
        [transitions.COMPLETE]: states.DELIVERED,
        [transitions.OPERATOR_COMPLETE]: states.DELIVERED,
        [transitions.OPERATOR_UPDATE_BOOKING_LATE]:
          states.CUSTOMER_REQUESTED_REFUND_NO_LONGER_POSSIBLE,
        [transitions.PROVIDER_UPDATE_BOOKING_LATE]:
          states.CUSTOMER_REQUESTED_REFUND_NO_LONGER_POSSIBLE,
        [transitions.BOOKING_START]: states.BOOKING_STARTED,
        [transitions.ZOOM_MEETING_CREATE_LATE]: states.CUSTOMER_REQUESTED_REFUND_NO_LONGER_POSSIBLE,
        [transitions.ZOOM_MEETING_PROVIDER_START_EARLY]:
          states.CUSTOMER_REQUESTED_REFUND_NO_LONGER_POSSIBLE,
      },
    },

    [states.BOOKING_STARTED]: {
      on: {
        [transitions.BOOKING_END]: states.BOOKING_ENDED,
        [transitions.ZOOM_MEETING_PROVIDER_START]: states.BOOKING_STARTED,
      },
    },

    [states.CANCELED]: {},
    [states.DELIVERED]: {
      on: {
        [transitions.EXPIRE_REVIEW_PERIOD]: states.REVIEWED,
        [transitions.REVIEW_1_BY_CUSTOMER]: states.REVIEWED_BY_CUSTOMER,
        [transitions.REVIEW_1_BY_PROVIDER]: states.REVIEWED_BY_PROVIDER,
      },
    },

    [states.REVIEWED_BY_CUSTOMER]: {
      on: {
        [transitions.REVIEW_2_BY_PROVIDER]: states.REVIEWED,
        [transitions.EXPIRE_PROVIDER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED_BY_PROVIDER]: {
      on: {
        [transitions.REVIEW_2_BY_CUSTOMER]: states.REVIEWED,
        [transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED]: { type: 'final' },
  },
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    transitions.ACCEPT,
    transitions.PROVIDER_UPDATE_REQUEST,
    transitions.PASSED_POINT_OF_CUSTOMER_CANCEL_WITH_REFUND,
    transitions.OPERATOR_ACCEPT,
    transitions.EARLY_CANCEL_CUSTOMER,
    transitions.PROVIDER_UPDATE_BOOKING,
    transitions.OPERATOR_UPDATE_BOOKING,
    transitions.PROVIDER_UPDATE_BOOKING_LATE,
    transitions.OPERATOR_UPDATE_BOOKING_LATE,
    transitions.CANCEL,
    transitions.CANCEL_CUSTOMER,
    transitions.CANCEL_PROVIDER,
    transitions.LATE_CANCEL_CUSTOMER,
    transitions.LATE_CANCEL_OPERATOR,
    transitions.LATE_CANCEL_PROVIDER,
    transitions.COMPLETE,
    transitions.OPERATOR_COMPLETE,
    transitions.CONFIRM_PAYMENT,
    transitions.DECLINE,
    transitions.OPERATOR_DECLINE,
    transitions.EXPIRE,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,
  ].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
export const isCustomerReview = transition => {
  return [transitions.REVIEW_1_BY_CUSTOMER, transitions.REVIEW_2_BY_CUSTOMER].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
export const isProviderReview = transition => {
  return [transitions.REVIEW_1_BY_PROVIDER, transitions.REVIEW_2_BY_PROVIDER].includes(transition);
};

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [
    transitions.REQUEST_PAYMENT,
    transitions.REQUEST_PAYMENT_AFTER_INQUIRY,
    transitions.PROVIDER_UPDATE_BOOKING,
    transitions.PROVIDER_UPDATE_REQUEST,
  ].includes(transition);
};

// Check when transaction is completed (booking over)
export const isCompleted = transition => {
  const txCompletedTransitions = [
    transitions.COMPLETE,
    transitions.OPERATOR_COMPLETE,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,
    transitions.EXPIRE_REVIEW_PERIOD,
    transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
    transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
  ];
  return txCompletedTransitions.includes(transition);
};

// Check when transaction is refunded (booking did not happen)
// In these transitions action/stripe-refund-payment is called
export const isRefunded = transition => {
  const txRefundedTransitions = [
    transitions.EXPIRE_PAYMENT,
    transitions.EXPIRE,
    transitions.CANCEL,
    transitions.EARLY_CANCEL_CUSTOMER,
    transitions.CANCEL_CUSTOMER,
    transitions.CANCEL_PROVIDER,
    transitions.LATE_CANCEL_CUSTOMER,
    transitions.LATE_CANCEL_OPERATOR,
    transitions.LATE_CANCEL_PROVIDER,
    transitions.DECLINE,
  ];
  return txRefundedTransitions.includes(transition);
};

export const isUpdateBookingTransition = transition => {
  const txUpdateBookingTransitions = [
    transitions.PROVIDER_UPDATE_BOOKING,
    transitions.PROVIDER_UPDATE_BOOKING_LATE,
  ];
  return txUpdateBookingTransitions.includes(transition);
};

export const isUpdateOfferTransition = transition => {
  const txUpdateOfferTransitions = [];
  return txUpdateOfferTransitions.includes(transition);
};

export const isUpdateRequestTransition = transition => {
  const txUpdateRequestTransitions = [transitions.PROVIDER_UPDATE_REQUEST];
  return txUpdateRequestTransitions.includes(transition);
};

export const isBookingPayTransition = transition => {
  const txPayTransitions = [transitions.ACCEPT];
  return txPayTransitions.includes(transition);
};

export const isBookingCancelTransition = transition => {
  const txCancelTransitions = [
    transitions.CANCEL,
    transitions.CANCEL_CUSTOMER,
    transitions.CANCEL_PROVIDER,
    transitions.LATE_CANCEL_CUSTOMER,
    transitions.LATE_CANCEL_OPERATOR,
    transitions.LATE_CANCEL_PROVIDER,
    transitions.EARLY_CANCEL_CUSTOMER,
  ];
  return txCancelTransitions.includes(transition);
};

export const isBookingEndTransition = transition => {
  const txEndTransitions = [transitions.BOOKING_END];
  return txEndTransitions.includes(transition);
};

export const isBookingDeclineTransition = transition => {
  const txDenyTransitions = [transitions.DECLINE];
  return txDenyTransitions.includes(transition);
};

export const statesNeedingProviderAttention = [states.PREAUTHORIZED];

export const transitionsNeedingProviderAttention = [
  transitions.CONFIRM_PAYMENT,
  transitions.INQUIRE,
  transitions.COMPLETE, // When review period starts
  transitions.REVIEW_1_BY_CUSTOMER,
  transitions.CANCEL_CUSTOMER,
  transitions.EARLY_CANCEL_CUSTOMER,
  transitions.LATE_CANCEL_CUSTOMER,
  transitions.CANCEL,
  transitions.LATE_CANCEL_OPERATOR,
  transitions.OPERATOR_DECLINE,
  transitions.OPERATOR_UPDATE_BOOKING,
  transitions.OPERATOR_UPDATE_BOOKING_LATE,
];

export const transitionsNeedingCustomerAttention = [
  transitions.REVIEW_1_BY_PROVIDER,
  transitions.COMPLETE, // When review period starts
  transitions.CANCEL_PROVIDER,
  transitions.LATE_CANCEL_PROVIDER,
  transitions.ACCEPT,
  transitions.CANCEL,
  transitions.DECLINE,
  transitions.LATE_CANCEL_OPERATOR,
  transitions.LATE_CANCEL_PROVIDER,
  transitions.OPERATOR_DECLINE,
  transitions.OPERATOR_UPDATE_BOOKING,
  transitions.OPERATOR_UPDATE_BOOKING_LATE,
  transitions.PROVIDER_UPDATE_BOOKING,
  transitions.PROVIDER_UPDATE_BOOKING_LATE,
  transitions.PROVIDER_UPDATE_REQUEST,
];
