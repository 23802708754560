/**
 * Usage without sizes:
 *   <ResponsiveImage
 *     alt="ListingX"
 *     image={imageDataFromSDK}
 *     variants={['landscape-crop', 'landscape-crop2x']}
 *   />
 *   // produces:
 *   <img
 *     alt="ListingX"
 *     src="url/to/landscape-crop.jpg"
 *     srcSet="url/to/landscape-crop.jpg 400w, url/to/landscape-crop2x.jpg 800w" />
 *
 * Usage with sizes:
 *   <ResponsiveImage
 *     alt="ListingX"
 *     image={imageDataFromSDK}
 *     variants={['landscape-crop', 'landscape-crop2x']}
 *     sizes="(max-width: 600px) 100vw, 50vw"
 *   />
 *   // produces:
 *   <img
 *     alt="ListingX"
 *     src="url/to/landscape-crop.jpg"
 *     srcSet="url/to/landscape-crop.jpg 400w, url/to/landscape-crop2x.jpg 800w"
 *     sizes="(max-width: 600px) 100vw, 50vw" />
 *
 *   // This means that below 600px image will take as many pixels there are available on current
 *   // viewport width (100vw) - and above that image will only take 50% of the page width.
 *   // Browser decides which image it will fetch based on current screen size.
 *
 * NOTE: for all the possible image variant names and their respective
 * sizes, see the API documentation.
 */

import React from 'react';
import { arrayOf, oneOfType, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import NoImageIcon from './NoImageIcon';
import css from './ResponsiveImage.module.css';

const ResponsiveImage = props => {
  const {
    className,
    rootClassName,
    alt,
    noImageMessage,
    image,
    variants,
    dimensions,
    ...rest
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (image == null || variants.length === 0) {
    const noImageClasses = classNames(rootClassName || css.root, css.noImageContainer, className);

    const noImageMessageText = noImageMessage || <FormattedMessage id="ResponsiveImage.noImage" />;
    return (
      <div className={noImageClasses}>
        <div className={css.noImageWrapper}>
          <NoImageIcon className={css.noImageIcon} />
          <div className={css.noImageText}>{noImageMessageText}</div>
        </div>
      </div>
    );
  }

  // Function to select the best option from the srcset
  const selectBestOption = srcset => {
    // Get the viewport width
    const documentWidth =
      typeof document !== 'undefined' ? document.documentElement.clientWidth : 0;
    const windowWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
    const viewportWidth = Math.max(documentWidth, windowWidth);
    const options = srcset.split(', ').map(option => {
      const [url, size] = option.trim().split(' ');
      return { url, size: parseFloat(size.replace('w', '')) };
    });

    // Sort the options by size
    options.sort((a, b) => a.size - b.size);

    // Find the best option based on viewport width
    let bestOption;

    // Default to the smallest size
    if (options.length > 0) {
      bestOption = options[0];
    }

    for (const option of options) {
      if (viewportWidth >= option.size) {
        bestOption = option;
      } else {
        break;
      }
    }

    // Return the URL of the best option
    return bestOption ? bestOption.url : '';
  };

  const imageVariants = image.attributes.variants;

  const srcSet = variants
    .map(variantName => {
      const variant = imageVariants[variantName];

      if (!variant) {
        // Variant not available (most like just not loaded yet)
        return null;
      }
      return `${variant.url} ${variant.width}w`;
    })
    .filter(v => v != null)
    .join(', ');

  const { fileType, filePath, videoCodec } = image?.ik || {};

  const imgProps = {
    className: classes,
    srcSet,
    ...rest,
  };

  if (!image.ik || fileType === 'image') {
    return <img alt={alt} {...imgProps} />;
  }

  if (fileType === 'non-image' && videoCodec) {
    // The width and height to request the video are taken from the AspectRatioWrapper
    const element =
      typeof window !== 'undefined' && typeof document !== 'undefined'
        ? document.querySelector('[class^="AspectRatioWrapper_aspectBox"]')
        : null;
    let url = '';
    if (element) {
      const width = element.scrollWidth;
      const height = element.scrollHeight;
      url = image.ik.url + '/ik-video.mp4' + `?tr=h-${height},w-${width},c-maintain_ratio`;
    } else {
      // Cant get the width and height from the AspectRatioWrapper - maybe doesn't exist in the DOM yet
      url = selectBestOption(srcSet).replace('?tr=', '/ik-video.mp4?tr=');
    }

    return (
      <video disablePictureInPicture loop muted playsInline autoPlay className={classes}>
        <source src={url} type="video/mp4" />
      </video>
    );
  }
  return null;
};

ResponsiveImage.defaultProps = {
  className: null,
  rootClassName: null,
  image: null,
  sizes: null,
  noImageMessage: null,
};

ResponsiveImage.propTypes = {
  className: string,
  rootClassName: string,
  alt: string.isRequired,
  image: oneOfType([propTypes.image, propTypes.imageAsset, propTypes.imageKitMedia]),
  variants: arrayOf(string).isRequired,
  sizes: string,
  noImageMessage: string,
};

export default ResponsiveImage;
