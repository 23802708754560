import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { MessageSquare, TicketMinus, UserRound } from 'lucide-react';
import { LinkTabNavHorizontal } from '../../components';

import css from './UserNav.module.css';

const UserNav = props => {
  const { className, rootClassName, currentPage, currentUser } = props;
  const classes = classNames(rootClassName || css.root, className);
  const inboxTab =
    currentUser?.attributes?.profile?.publicData?.userType === 'provider' ? 'sales' : 'orders';
  const tabs = [
    {
      text: <FormattedMessage id="UserNav.profileSettings" />,
      icon: <UserRound />,
      selected: currentPage === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.yourListings" />,
      icon: <TicketMinus />,
      selected: currentPage === 'ManageListingsPage',
      linkProps: {
        name: 'ManageListingsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.inbox" />,
      icon: <MessageSquare />,
      selected: currentPage === 'InboxPage',
      disabled: false,
      linkProps: {
        name: 'InboxPage',
        params: { tab: inboxTab ? inboxTab : 'orders' },
      },
    },
  ];

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,
};

export default UserNav;
