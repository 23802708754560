import React, { Component } from 'react';
import { bool, func, number, object, oneOfType, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '../../components';

import css from './FieldDateTimeInput.module.css';

const FieldDateTimeInputComponent = props => {
  const {
    rootClassName,
    className,
    inputRootClass,
    customErrorText,
    id,
    label,
    optionalLabel,
    input,
    meta,
    onUnmount,
    isUncontrolled,
    inputRef,
    hideErrorMessage,
    min,
    max,
    step,
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const value = input?.value || currentDateTime();

  const handleChange = e => {
    input.onChange(e);
    if (onChange) {
      onChange(e.currentTarget.value);
    }
  };

  const { valid, invalid, touched, error } = meta;

  const errorText = customErrorText || error;
  const hasError = !!customErrorText || !!(touched && invalid && error);

  const fieldMeta = { touched: hasError, error: errorText };

  const { type, ...inputWithoutType } = input;
  const { value: defaultValue, ...inputWithoutValue } = input;
  const refMaybe = inputRef ? { ref: inputRef } : {};

  const inputClasses =
    inputRootClass ||
    classNames(css.input, {
      [css.inputSuccess]: valid,
      [css.inputError]: hasError,
    });

  const handleFocus = event => {
    if (event.target.showPicker) {
      event.target.showPicker();
    }
  };

  const inputProps = {
    className: inputClasses,
    id,
    ...refMaybe,
    ...inputWithoutType,
    ...rest,
    value: value || '',
    onFocus: handleFocus,
    onChange: handleChange,
  };

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {label ? (
        <label htmlFor={id} className={optionalLabel && css.inlineLabel}>
          {label}
        </label>
      ) : null}
      {label && optionalLabel ? <span className={css.optional}>{optionalLabel}</span> : null}
      <div className={css.dateTimeInput}>
        <input type="datetime-local" {...inputProps} />
      </div>

      {hideErrorMessage ? null : <ValidationError fieldMeta={fieldMeta} />}
    </div>
  );
};

FieldDateTimeInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  optionalLabel: null,
  isUncontrolled: false,
  inputRef: null,
  min: null,
  max: null,
  step: 60, // i minute
  onChange: () => {},
};

FieldDateTimeInputComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,
  onUnmount: func,
  customErrorText: string,
  id: string,
  label: string,
  optionalLabel: string,
  isUncontrolled: bool,
  inputRef: object,
  input: shape({
    onChange: func.isRequired,
    value: oneOfType([string, number]),
  }).isRequired,
  meta: object.isRequired,
  onChange: func.isRequired,
  min: string,
  max: string,
  step: number,
};

class FieldDateTimeInput extends Component {
  componentWillUnmount() {
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <Field component={FieldDateTimeInputComponent} {...this.props} />;
  }
}

export default FieldDateTimeInput;
