import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconBookyrslfLogoText.module.css';

const IconBookyrslfLogoText = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="260"
      height="69"
      viewBox="0 0 260 69"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs id="defs1" />
      <g id="Group">
        <path
          id="Vector"
          d="m 140.176,8.15461 h 4.492 c 0.543,5.79309 1.085,11.53439 1.634,17.39799 0.746,0 1.457,0.0373 2.159,-0.027 0.179,-0.0165 0.375,-0.3398 0.477,-0.5594 1.243,-2.6065 2.472,-5.2192 3.703,-7.8319 1.342,-2.8571 2.679,-5.7143 4.031,-8.56323 0.09,-0.18854 0.309,-0.43925 0.477,-0.44339 1.467,-0.03522 2.936,-0.02072 4.525,-0.02072 -0.168,0.39574 -0.282,0.68996 -0.416,0.97174 -2.205,4.6349 -4.409,9.2739 -6.62,13.9109 -2.267,4.7551 -4.55,9.5039 -6.796,14.2714 -0.255,0.5366 -0.526,0.7583 -1.129,0.7376 -1.306,-0.0476 -2.613,-0.0145 -4.055,-0.0145 1.338,-2.7577 2.629,-5.4118 3.97,-8.1717 h -4.32 c -0.707,-7.2289 -1.415,-14.3978 -2.132,-21.65571 z"
        />
        <path
          id="Vector_2"
          d="m 197.304,14.3845 h -4.597 c -1.364,-2.7784 -5.099,-3.1286 -7.001,-2.3764 -0.427,0.1678 -0.827,0.5076 -1.123,0.866 -0.732,0.8868 -0.547,1.9953 0.495,2.4925 1.202,0.576 2.476,0.9966 3.721,1.4794 1.318,0.5159 2.64,1.0235 3.964,1.5353 0.052,0.0207 0.095,0.0456 0.145,0.0684 3.752,1.9538 4.382,6.3483 1.347,9.3257 -1.312,1.2867 -2.922,1.9911 -4.697,2.277 -2.441,0.3958 -4.876,0.3088 -7.205,-0.6112 -2.753,-1.0877 -4.525,-3.0623 -5.198,-5.9754 -0.025,-0.1036 -0.025,-0.2093 -0.046,-0.4185 h 0.811 c 0.725,0 1.448,0.0414 2.169,-0.0104 1.05,-0.0746 1.86,0.0062 2.469,1.1478 0.643,1.208 1.985,1.7177 3.307,1.8979 1.047,0.1451 2.136,0.0995 3.201,0.027 0.788,-0.056 1.498,-0.3916 1.996,-1.065 0.683,-0.9241 0.538,-1.9683 -0.45,-2.5485 -0.711,-0.4185 -1.488,-0.7376 -2.258,-1.0276 -1.353,-0.5056 -2.737,-0.9262 -4.09,-1.4359 -0.674,-0.2548 -1.326,-0.5843 -1.95,-0.9406 -3.897,-2.246 -3.182,-6.3588 -1.457,-8.4846 0.869,-1.06701 1.975,-1.82948 3.255,-2.26872 3.214,-1.10227 6.405,-0.9821 9.426,0.60499 2.132,1.11673 3.369,2.97113 3.771,5.43883 z"
        />
        <path
          id="Vector_3"
          d="m 215.981,29.7777 h -4.446 c 1.034,-5.8718 2.06,-11.696 3.108,-17.6528 h -2.561 c 0.251,-1.3882 0.475,-2.63963 0.715,-3.99052 h 2.548 C 215.832,5.37457 216.307,2.70179 216.783,0 h 10.847 c -0.214,1.30738 -0.427,2.58576 -0.649,3.94909 h -6.416 c -0.245,1.3799 -0.475,2.68522 -0.728,4.12313 h 5.12 c -0.246,1.38819 -0.466,2.63758 -0.706,3.99258 h -5.131 c -1.05,5.9464 -2.088,11.8058 -3.134,17.7108 z"
        />
        <path
          id="Vector_4"
          d="m 204.308,0 h 4.453 c -1.509,8.59849 -3.004,17.1369 -4.519,25.7872 h 4.904 c -0.244,1.3902 -0.466,2.6686 -0.694,3.976 H 199.07 C 200.819,19.8325 202.559,9.93073 204.306,0 Z"
        />
        <path
          id="Vector_5"
          d="m 168.894,11.3031 c 2.373,-2.88827 5.356,-4.16872 9.158,-3.52642 -0.246,1.40683 -0.495,2.81992 -0.762,4.33242 -0.916,0 -1.792,0.0103 -2.669,-0.0042 -1.405,-0.0207 -2.702,0.3543 -3.887,1.0878 -1.755,1.0919 -2.822,2.6852 -3.201,4.697 -0.615,3.282 -1.171,6.5722 -1.759,9.8624 -0.116,0.6568 -0.255,1.3053 -0.387,2.0015 h -4.426 c 1.274,-7.2041 2.544,-14.3875 3.823,-21.60191 h 4.44 c -0.172,1.05461 -0.334,2.03881 -0.491,3.02501 0.056,0.0456 0.105,0.0829 0.161,0.1243 z"
        />
        <path
          id="Vector_6"
          d="m 222.539,27.6902 c -0.014,-1.8689 1.436,-3.4208 3.203,-3.427 1.6,-0.0062 2.748,1.0878 2.748,2.6127 -0.004,1.8233 -1.436,3.3441 -3.181,3.369 -1.645,0.0248 -2.757,-1.0028 -2.77,-2.5547 z"
        />
        <path
          id="Vector_7"
          d="M 41.7385,29.4168 H 34.8203 C 36.5069,19.8507 38.181,10.3385 39.8655,0.772461 h 6.9471 C 46.2324,4.09583 45.6627,7.3819 45.0888,10.668 c 0.0476,0.0414 0.0932,0.0828 0.1408,0.1243 0.1948,-0.1471 0.4124,-0.2714 0.5802,-0.4455 1.2328,-1.2597 2.6334,-2.25216 4.3738,-2.66033 3.6321,-0.84741 7.3408,1.04011 8.9176,4.54373 2.2563,5.0141 0.5532,13.2438 -5.0306,16.4656 -2.7847,1.6078 -5.6854,1.9787 -8.6297,0.375 -1.0442,-0.5656 -1.8481,-1.3902 -2.4345,-2.4304 -0.1036,-0.1781 -0.3127,-0.2983 -0.4743,-0.4433 -0.0995,0.1844 -0.2508,0.3563 -0.2922,0.5552 -0.1844,0.8827 -0.3356,1.7674 -0.5014,2.6687 z m 11.122,-11.5302 c 0.0352,-1.6389 -0.3335,-3.1183 -1.9019,-3.9388 -1.6348,-0.8557 -3.3069,-0.7728 -4.8484,0.2549 -2.2667,1.5042 -3.3233,5.5962 -2.0097,7.9769 0.3501,0.6319 0.9883,1.2473 1.6409,1.5456 1.5353,0.7045 3.1286,0.632 4.5727,-0.3335 1.9538,-1.3157 2.503,-3.3006 2.5444,-5.5051 z"
        />
        <path
          id="Vector_8"
          d="m 120.391,29.4362 h -6.953 c 1.686,-9.5702 3.36,-19.0886 5.047,-28.658856 h 6.949 C 124.566,5.7251 123.7,10.6397 122.821,15.6393 c 1.123,0.0518 2.053,0.2962 2.864,-0.8309 1.551,-2.1527 3.296,-4.1646 4.974,-6.22197 0.166,-0.19891 0.429,-0.44546 0.653,-0.44961 2.345,-0.03522 4.691,-0.02072 7.231,-0.02072 -0.501,0.65265 -0.889,1.17685 -1.297,1.68655 -2.07,2.60645 -4.133,5.21505 -6.228,7.80075 -0.325,0.4061 -0.354,0.7107 -0.151,1.1748 1.388,3.1908 2.743,6.394 4.106,9.593 0.137,0.3253 0.257,0.6589 0.423,1.0961 h -4.034 c -0.955,0 -1.913,-0.0269 -2.868,0.0103 -0.497,0.0208 -0.717,-0.1616 -0.903,-0.6112 -0.916,-2.2149 -1.886,-4.4049 -2.801,-6.6198 -0.195,-0.4703 -0.436,-0.663 -0.968,-0.6588 -2.043,0.0062 -2.043,-0.0146 -2.399,2.0056 -0.34,1.9269 -0.68,3.8517 -1.03,5.8449 z"
        />
        <path
          id="Vector_9"
          d="m 76.1175,7.51478 c 2.8427,0.04765 6.1226,1.33846 8.3312,4.59142 2.3724,3.4994 2.3869,7.2558 0.4931,10.9356 -2.7784,5.4015 -8.615,8.0142 -14.5594,6.6985 C 64.1873,28.3708 60.6796,21.867 62.9235,15.9289 64.8732,10.7636 69.593,7.50649 76.1154,7.5127 Z m 2.9898,10.74702 c -0.0042,-2.0097 -0.5656,-3.4911 -2.1838,-4.3261 -1.7072,-0.8785 -3.4208,-0.7252 -4.9789,0.3853 -1.8585,1.3219 -2.5692,3.224 -2.5547,5.4388 0.0104,1.6659 0.6279,3.0561 2.1425,3.8518 1.5456,0.8142 3.1597,0.7686 4.666,-0.1347 2.0574,-1.2287 2.8156,-3.1866 2.9089,-5.2151 z"
        />
        <path
          id="Vector_10"
          d="m 102.304,7.51567 c 2.242,0.02487 5.234,1.09812 7.497,3.74193 2.732,3.1928 3.201,7.8298 1.257,11.6069 -2.815,5.474 -8.704,8.2898 -14.6959,6.8539 -6.3587,-1.5249 -9.6034,-7.5542 -7.4548,-13.7285 1.8233,-5.2254 6.6758,-8.48873 13.3987,-8.47423 z m 2.816,10.63523 c -0.025,-1.8751 -0.574,-3.3918 -2.231,-4.233 -1.714,-0.8681 -3.4231,-0.6941 -4.9833,0.4186 -1.7777,1.27 -2.4926,3.1037 -2.5299,5.2253 -0.0311,1.7446 0.5594,3.2385 2.1797,4.0652 1.6389,0.8349 3.3105,0.7355 4.8625,-0.288 1.892,-1.2535 2.576,-3.139 2.702,-5.1881 z"
        />
        <path
          id="Vector_11"
          d="M 27.671,14.6837 26.3119,13.3556 C 25.8706,12.9225 25.0045,12.5703 24.385,12.5703 H 19.1472 C 18.0926,12.1684 18.8157,11.2671 18.8157,11.2671 L 20.8814,9.17029 C 21.3165,8.72897 21.302,8.02659 20.8524,7.60392 L 14.821,1.9227 C 14.3714,1.50002 13.6462,1.51038 13.209,1.94549 L 10.8926,4.25361 C 10.4554,4.68664 10.4513,5.4056 10.8844,5.84278 l 5.2046,5.26062 c 0.0414,0.0539 0.0933,0.1098 0.1616,0.1637 0.0042,0.002 0.0062,0.0062 0.0104,0.0083 l 0.4123,0.4185 c 0.4849,0.6796 -0.4227,0.8764 -0.4227,0.8764 H 1.12297 C 0.505533,12.5703 0,13.0759 0,13.6933 h 0.00414789 v 3.5492 c 0,0.6154 0.50340911,1.123 1.12084211,1.123 H 16.2113 c 0.6174,0 0.7666,0.3564 0.3294,0.7935 l -5.6481,5.646 c -0.4351,0.4372 -0.4309,1.1437 0.0145,1.5747 l 2.5817,2.4904 c 0.4454,0.4269 1.1622,0.4227 1.5994,-0.0145 L 27.6793,16.2625 c 0.4351,-0.4372 0.431,-1.1479 -0.0103,-1.5788 z"
        />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconBookyrslfLogoText.defaultProps = {
  className: null,
  rootClassName: null,
};

IconBookyrslfLogoText.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconBookyrslfLogoText;
