import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconChat.module.css';

const IconChat = props => {
  const { className, rootClassName, size } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="51"
      height="51"
      viewBox="0 0 51 51"
      fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 33.496V20.6768C18 19.7507 18.7959 19 19.7778 19H32.2223C33.204 19 34 19.7507 34 20.6768V29.0611C34 29.9872 33.204 30.7379 32.2223 30.7379H22.41C21.8699 30.7379 21.3592 30.9695 21.0218 31.3673L18.9498 33.8102C18.6349 34.1815 18 33.9715 18 33.496Z"
        strokeWidth="1.5"
      />
    </svg>
  );


};

const { string } = PropTypes;

IconChat.defaultProps = {
  className: null,
  rootClassName: null,
};

IconChat.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconChat;
