import { pick } from 'lodash';
import { getPayouts } from '../../util/api';

// ================ Action types ================ //
export const SET_INITIAL_VALUES = 'app/PayoutsPage/SET_INITIAL_VALUES';
export const FETCH_PAYOUTS_REQUEST = 'app/PayoutsPage/FETCH_PAYOUTS_REQUEST';
export const FETCH_PAYOUTS_SUCCESS = 'app/PayoutsPage/FETCH_PAYOUTS_SUCCESS';
export const FETCH_PAYOUTS_ERROR = 'app/PayoutsPage/FETCH_PAYOUTS_ERROR';
export const SET_ROWS_PER_PAGE = 'app/PayoutsPage/SET_ROWS_PER_PAGE';
export const SET_CURRENT_PAGE = 'app/PayoutsPage/SET_CURRENT_PAGE';
export const SET_SEARCH_QUERY = 'app/PayoutsPage/SET_SEARCH_QUERY';
export const SET_LAST_PAYOUT_ID = 'app/PayoutsPage/SET_LAST_PAYMENT_ID';

// ================ Initial State ================ //
const defaultApiRowsPerPage = 25;
const initialState = {
  payouts: [],
  loading: false,
  error: null,
  uiRowsPerPage: 5,
  currentPage: 0,
  searchQuery: '',
  lastPayoutId: null,
  totalCount: 0,
};

// ================ Reducer ================ //
export default function stripePayoutsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_PAYOUTS_REQUEST:
      return { ...state, loading: true, error: null };

    case FETCH_PAYOUTS_SUCCESS:
      const existingPayoutIds = new Set(state.payouts.map(payout => payout.id));
      const newPayouts = payload?.payouts?.filter(payout => !existingPayoutIds.has(payout.id));

      return {
        ...state,
        loading: false,
        totalCount: payload?.totalCount,
        payouts: [...state.payouts, ...newPayouts],
        lastPayoutId:
          newPayouts.length > 0 ? newPayouts[newPayouts.length - 1].id : state.lastPayoutId,
      };

    case FETCH_PAYOUTS_ERROR:
      return { ...state, loading: false, error: payload };

    case SET_ROWS_PER_PAGE:
      return {
        ...state,
        uiRowsPerPage: payload,
        currentPage: 0,
        // payouts: [], // Clear payouts to re-fetch
      };

    case SET_CURRENT_PAGE:
      return { ...state, currentPage: payload };

    case SET_SEARCH_QUERY:
      return { ...state, searchQuery: payload, currentPage: 0, payouts: [], lastPayoutId: null };

    case SET_LAST_PAYOUT_ID:
      return { ...state, lastPayoutId: payload };

    default:
      return state;
  }
}

// ================ Thunks to fetch payouts ================ //
export const fetchPayouts = (rowsPerPage, lastId, searchQuery) => dispatch => {
  dispatch(fetchPayoutsRequest());

  const apiRowsPerPage = rowsPerPage > defaultApiRowsPerPage ? rowsPerPage : defaultApiRowsPerPage;

  return getPayouts(apiRowsPerPage, lastId, searchQuery)
    .then(response => {
      dispatch(fetchPayoutsSuccess({ payouts: response.payouts, totalCount: response.totalCount }));
    })
    .catch(error => {
      dispatch(fetchPayoutsError(error.message));
    });
};

// ================ Load initial payouts data ================ //
export const loadData = () => (dispatch, getState) => {
  dispatch(fetchPayoutsRequest());
  const { uiRowsPerPage, searchQuery, lastPayoutId } = getState().StripePayoutsPage;

  // Clear state so that previously loaded data is not visible
  dispatch(setInitialValues());

  return getPayouts(defaultApiRowsPerPage, lastPayoutId, searchQuery)
    .then(response => {
      dispatch(fetchPayoutsSuccess({ payouts: response.payouts, totalCount: response.totalCount }));
    })
    .catch(error => {
      dispatch(fetchPayoutsError(error.message));
    });
};

// ================ Thunks for updating filters ================ //

export const setRowsPerPage = rowsPerPage => (dispatch, getState) => {
  const state = getState().StripePayoutsPage;
  const { payouts } = state;

  // Set new rows per page and reset to the first page
  dispatch({ type: SET_ROWS_PER_PAGE, payload: rowsPerPage });
  dispatch({ type: SET_CURRENT_PAGE, payload: 0 });

  // Check if we have enough payouts for the first page after changing rows per page
  const requiredPayouts = rowsPerPage; // How many payouts we need for the new page

  if (payouts.length >= requiredPayouts) {
    // If we already have enough data, no need to fetch
    return;
  }

  // If we don't have enough data, fetch more payouts
  dispatch(fetchPayouts(rowsPerPage, null, ''));
};

export const setCurrentPage = currentPage => (dispatch, getState) => {
  const state = getState().StripePayoutsPage;
  const { uiRowsPerPage, searchQuery, payouts } = state;

  // Set the new current page
  dispatch({ type: SET_CURRENT_PAGE, payload: currentPage });

  // Calculate the starting index for the current page
  const startIndex = currentPage * uiRowsPerPage;
  const endIndex = startIndex + uiRowsPerPage;

  // Check if we have enough payouts for the requested page
  if (payouts.length >= endIndex) {
    // We already have enough payouts for the requested page, no need to fetch
    return;
  }

  // If we don't have enough payouts, fetch more payouts
  const lastPayoutId = payouts.length > 0 ? payouts[payouts.length - 1].id : null;
  dispatch(fetchPayouts(uiRowsPerPage, lastPayoutId, searchQuery));
};

// ================ Set Search Query ================ //
export const setSearchQuery = searchQuery => dispatch => {
  dispatch({ type: SET_SEARCH_QUERY, payload: searchQuery });
  dispatch(fetchPayouts(initialState.uiRowsPerPage, null, searchQuery)); // Reset to page 0 on search
};

// ================ Action creators ================ //
export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});
export const fetchPayoutsRequest = () => ({ type: FETCH_PAYOUTS_REQUEST });
export const fetchPayoutsSuccess = payload => ({
  type: FETCH_PAYOUTS_SUCCESS,
  payload: payload,
});
export const fetchPayoutsError = error => ({
  type: FETCH_PAYOUTS_ERROR,
  payload: error,
});

// ================ Method to get a payment by ID ================ //
export const getPaymentById = id => (dispatch, getState, sdk) => {
  return sdk
    .fetchPaymentById(id)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw new Error(error.message);
    });
};
